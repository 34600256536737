import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { faBan } from '@fortawesome/pro-solid-svg-icons'
import Layout from '../components/layoutNewsItem'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import EventSection from '../components/eventSection'
import EventSponsors from '../components/eventSponsors'
import moment from 'moment'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {MDBRow, MDBCol, MDBContainer} from 'mdbreact'
import ColumnAlternatingYouTube from '../components/columnAlternatingYouTube'

function filterByPublishTo(arr, publishTo) {
  return arr.filter(obj =>
    obj.publishTo.some(publish => publish.url === publishTo)
  )
}

class EventItemTemplate extends React.Component {
  render() {
    const event = get(this.props, 'data.contentfulEvent')
    const heroImage = get(this.props, 'data.heroImage')
    const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
    const pageUrl = siteUrl + '/events/' + event.slug
    const sponsorshipUrl = 'https://assets.ctfassets.net/tr0m6h02ct4z/3KdydWKVqZZoSQEVokTW4q/5040b051aa62ee1fb29eccda20b690ea/UEI_Golf_Classic_2024_Sponsorship_Form.pdf'

    return (
      <>
        <Layout location={this.props.location}>
          <div id="sub-page">
            <SEO 
              title={event.metaTitle} 
              description={event.metaDescription} 
              url={pageUrl} 
              image={event.hero ? 'https:' + event.hero.file.url : siteUrl + heroImage.childImageSharp.fluid.src} 
            />

            <Hero
                class="intro-65"
                image={event.hero ? event.hero.localFile.childImageSharp.fluid : heroImage.childImageSharp.fluid}
                title={event.title}
                subtitle={event.subtitle}
                type="root" /* change this to event */
            />
          </div>

          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol lg={event.image ? '7' : '12'}>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">
                    {moment(event.date).format('MMMM DD, YYYY - HH:mm')} ({event.timezone}) 
                  </h2>
                  <h3 className="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium">
                    Duration: {event.duration}
                  </h3>

                  <div>
                    {documentToReactComponents(event.body.json, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p className="font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }} >
                            {children}
                          </p>
                        ),
                        [BLOCKS.HEADING_3]: (node, children) => (
                          <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2" style={{ whiteSpace: 'pre-wrap' }} >
                            {children}
                          </h3>
                        ),
                        [INLINES.HYPERLINK]: (node, children) =>
                        node.data.uri.charAt(0) == '/' ? (
                          <Link to={node.data.uri}>{children}</Link>
                        ) : (
                          <a href={node.data.uri} className="effect" target="_blank" >
                            {children}
                          </a>
                        ),
                        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                          /* need to change style to class and cater for mobile */
                          <img
                            style={{
                              display: 'block',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              width: '50%',
                              paddingTop: '10px',
                              paddingBottom: '20px',
                            }}
                            src={`${node.data.target.fields.file['en-US'].url}?w=600&q=90`}
                            alt={node.data.target.fields.title}
                          />
                        ),
                      },
                    })}
                  </div>

             {(event.section || event.postSection) && (
                <>
                  {moment(event.date).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD') ? (
                    <div>
                        <a href={sponsorshipUrl} className="nav-link btn btn-mdb-color mt-4" target="_blank">
                          <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                          Sponsorship
                        </a>
                    </div>
                 ) : ( 
                 <button className="nav-link btn btn-mdb-color mt-4" > <FontAwesomeIcon icon={faBan} className="mr-2" />Sponsorship closed</button>
               )}
            </>
          )}
            </MDBCol>
                <MDBCol lg="5" md="12">
                  {event.image && (
                    <Img
                      fluid={event.image.fluid}
                      className="image-fluid rounded mt-5 mt-md-5 mt-lg-0"
                      alt={event.alttext}
                    />
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          {event.section ? (
            <section className="bg-white-grey-gradient">
              <MDBContainer>
                <MDBRow>
                  {event.section.map((sections, index) => {
                    return (
                        <EventSection section={sections} />
                    )
                  })}
                </MDBRow>
              </MDBContainer>
            </section>
          ) : (
            ''
          )}

        <section className="bg-white">
          <MDBContainer>
              <MDBRow>
                <MDBCol lg="12">
                    <h2 className={`font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large text-center pb-1`}>
                      UNICOM Engineering Annual Golf Classic 2023
                    </h2>

                    <p className="font-alt font-w-700 letter-spacing-1 text-center">
                        Watch some highlights of last year's tournament
                    </p>
                  </MDBCol>
              </MDBRow>

              <MDBRow className="justify-content-center">
                <MDBCol lg="10" md="12">
                    <div className="embed-responsive embed-responsive-16by9 mt-5">
                      <iframe
                          className="embed-responsive-item rounded border"
                          src="https://www.youtube-nocookie.com/embed/fOpv7DoJykk?feature=oembed&rel=0&modestbranding=1"
                          allowfullscreen="allowfullscreen"
                      ></iframe>
                    </div>
                </MDBCol>
              </MDBRow>
          </MDBContainer>
        </section>   

          {event.sponsor ? (
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="12">
                  <div>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">
                      {event.sponsor.title}
                    </h2>
                    {documentToReactComponents(
                      event.sponsor
                        .childContentfulEventSponsorDescriptionRichTextNode
                        .json,
                      {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                            <p
                              className="font-w-400 text-medium"
                              style={{ whiteSpace: 'pre-wrap' }}
                            >
                              {children}
                            </p>
                          ),
                          [INLINES.HYPERLINK]: (node, children) =>
                          node.data.uri.charAt(0) == '/' ? (
                            <Link to={node.data.uri}>{children}</Link>
                          ) : (
                            <a
                              href={node.data.uri}
                              className="effect"
                              target="_blank"
                            >
                              {children}
                            </a>
                          ),
                          [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                            /* need to change style to class and cater for mobile */
                            <img
                              style={{
                                display: 'block',
/*                                 marginLeft: 'auto',
                                marginRight: 'auto', */
                                width: '25%',
                                paddingTop: '50px',
                                paddingBottom: '30px',
                              }}
                              src={`${node.data.target.fields.file['en-US'].url}?w=340&q=90`}
                              alt={node.data.target.fields.title}
                            />                            
                          ),
                        },
                      }
                    )}
                  </div>
                  <EventSponsors />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
          ) : (
            ''
          )}

          {event.postSection ? (
          <section className={(event.postSection) ? 'bg-white' : 'bg-white-grey-gradient' }>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div>
                    {documentToReactComponents(event.postSection.json, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p
                            className="font-w-400 text-medium"
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {children}
                          </p>
                        ),
                        [BLOCKS.HEADING_3]: (node, children) => (
                          <h3
                            className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2"
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {children}
                          </h3>
                        ),
                        [INLINES.HYPERLINK]: (node, children) =>
                        node.data.uri.charAt(0) == '/' ? (
                          <Link to={node.data.uri}>{children}</Link>
                        ) : (
                          <a
                            href={node.data.uri}
                            className="effect"
                            target="_blank"
                          >
                            {children}
                          </a>
                        ),
                        [INLINES.ASSET_HYPERLINK]: function assetHyperlink(node, children) {
                          const locale = "en-US";
                          const { nodeType, data } = node;
                          const url = data.target.fields.file[locale].url;
                          switch (nodeType) {
                            case "asset-hyperlink":
                              return (
                                <a href={url} target="_blank" className="effect">
                                  {children}
                                </a>
                              );
                            default:
                              console.warn(nodeType + " not set");
                          }
                        },
                        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                          /* need to change style to class and cater for mobile */
                          <img
                            style={{
                              display: 'block',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              width: '50%',
                              paddingTop: '10px',
                              paddingBottom: '20px',
                            }}
                            src={`${node.data.target.fields.file['en-US'].url}?w=600&q=90`}
                            alt={node.data.target.fields.title}
                          />
                        ),
                      },
                    })}
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
          ) : (
            ''
          )}

          {moment(event.date).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD') && (
            <section className="bg-blue-green-gradient">
              <MDBContainer>
                <MDBRow>
                  <MDBCol lg="12">
                    <div>
                      <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large text-white">
                      {event.cta.title}
                      </h2>
                      {documentToReactComponents(event.cta.description.json, {
                      renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p className="font-w-400 text-sm-large text-large-cta text-white" style={{ whiteSpace: 'pre-wrap' }} >
                          {children}
                          </p>
                          ),
                          [INLINES.HYPERLINK]: (node, children) =>
                          node.data.uri.charAt(0) == '/' ? (
                            <Link to={node.data.uri}>{children}</Link>
                          ) : (
                            <a
                              href={node.data.uri}
                              className="effect-white"
                              target="_blank"
                            >
                              {children}
                            </a>
                          ),
                      },
                      })}
                      {/* <a href={event.cta.link} className="nav-link btn btn-cta-color mt-4" >                 
                      <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                          {event.cta.linktext}
                      </a> */}
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          )}
        </Layout>
      </>
    )
  }
}

export default EventItemTemplate

export const pageQuery = graphql`
  query contentfulEvents($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulEvent(slug: { eq: $slug }) {
      body {
        json
      }
      cta {
        description {
          json
        }
        title
        link
        linktext
      }
      metaTitle
      metaDescription
      altText
      title
      subtitle
      date
      timezone
      duration
      image {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      altText
      hero {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      } 
      file {
        url
      }
    }
      postSection {
        json
      }
      section {
        title
        subtitle
        imageonly
        image {
          fluid(maxWidth: 2400, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        altText
        childContentfulEventSectionDescriptionRichTextNode {
          json
        }
        feature {
          childContentfulEventSectionFeaturesDescriptionRichTextNode {
            json
          }
          title
          subtitle
          info
          link
          linktext
        }
      }
      sponsor {
        childContentfulEventSponsorDescriptionRichTextNode {
          json
        }
        title
      }
    }
    heroImage: file(name: { eq: "events-default" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`