import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'

const logos = [
    {
        image: '/assets/carry-the-load-2023.png',
        alttext: 'carry the load sponsor logo',
        url: 'https://www.carrytheload.org/',
    },
    {
        image: '/assets/will-to-live-2023.png',
        alttext: 'will to livesponsor logo',
        url: 'https://will-to-live.org/',
    },
    {
        image: '/assets/collin-county-childrens-advocacy-center-2023.png',
        alttext: 'childrens advocacy center of collin county sponsor logo',
        url: 'https://www.caccollincounty.org/',
    },
    {
        image: '/assets/new-england-center-and-home-for-veterans-2023.png',
        alttext: 'nechv sponsor logo',
        url: 'https://nechv.org/',
    },
    {
        image: '/assets/rosies-place-2023.png',
        alttext: 'rosies place sponsor logo',
        url: 'https://www.rosiesplace.org/',
    },
    {
        image: '/assets/enable-ireland-2023.png',
        alttext: 'enable ireland sponsor logo',
        url: 'https://enableireland.ie/',
    },
    {
        image: '/assets/pieta-house-2023.png',
        alttext: 'pieta sponsor logo',
        url: 'https://www.pieta.ie/',
    },    
    {
      image: '/assets/tackle-tomorrow-2023.png',
      alttext: 'tackle tomorrow sponsor logo',
      url: 'http://tackletomorrow.com/',
  },     
]

class EventSponsors extends React.Component {
  render() {
    return (
        <MDBRow>
          {logos.map((logo, index) => {
            return (
              <MDBCol lg="3" md="6" className="text-center my-5" key={index}>
                    <a href={logo.url} target="_blank">
                        <img src={logo.image} loading='lazy' width="65%" />
                    </a>
              </MDBCol>
            )
          })}
        </MDBRow>
    )
  }
}

export default EventSponsors
